var ko = require("knockout");

var Constants = require("../Core/Constants");
var Browser = require("../Core/BrowserControl");
var ComponentEvent = require("../Core/ComponentEvent");
var BrandingHelpers = require("../Core/BrandingHelpers");
var Helpers = require("../Core/Helpers");

var w = window;
var PaginatedState = Constants.PaginatedState;
var BrowserHelper = Browser.Helper;
var StringHelpers = Helpers.String;

function ConsentErrorViewModel(params)
{
    var _this = this;

    
    var _serverData = params.serverData;
    var _customStrings = params.customStrings;
    var _isCustomizationFailure = params.isCustomizationFailure;

    var _strings = _serverData.str;
    var _cancelPostParams = _serverData.oCancelUriParams;
    var _dynamicTenantBranding = _serverData.dynamicTenantBranding;
    var _cancelUrl = _serverData.urlCancel;
    var _appName = _serverData.strHeaderText;
    var _appVerifiedPublisherName = _serverData.sAppVerifiedPublisherName;
    var _appWebsite = _serverData.strAppRootDomain;
    var _appLogoUrl = _serverData.urlAppLogo;
    var _isAppDomainUnverified = _serverData.fIsAppDomainUnverified;
    var _riskyAppLearnMoreUrl = _serverData.urlLearnMoreRiskyApp;
    var _isM365Certified = _serverData.fIsAppM365Certified;
    var _errorCode = _serverData.iErrorCode;
    

    
    var _moreInfo = null;
    

    
    _this.onSwitchView = ComponentEvent.create();
    _this.onRedirect = ComponentEvent.create();
    _this.onShowDebugDetails = ComponentEvent.create();
    

    
    _this.publisherWebsite = null;
    _this.tenantBranding = null;
    _this.unsafe_appName = null;
    _this.unsafe_consentErrorDesc = null;
    _this.unsafe_appVerifiedPublisherName = null;
    _this.unsafe_appVerifiedPublisher_AriaLabel = null;
    _this.unsafe_isM365CertifiedAriaLabel = null;
    _this.domainNameState = "unverified";

    _this.appLogoUrl = ko.observable(_appLogoUrl).extend({ loadImageFromUrl: null });
    _this.customTitle = ko.observable(null);
    _this.customDescription = ko.observable(null);
    

    
    _this.saveSharedData = function (sharedData)
    {
        if (_moreInfo)
        {
            sharedData.moreInfo = _moreInfo;
        }
    };

    _this.getState = function ()
    {
    };

    _this.restoreState = function ()
    {
    };
    

    
    _this.cancel_onClick = function ()
    {
        _this.onRedirect(_cancelUrl, _cancelPostParams);
    };

    _this.riskyAppLearnMore_onClick = function ()
    {
        _moreInfo = StringHelpers.format(_strings["STR_Consent_RiskyApp_ExternalLink"], _riskyAppLearnMoreUrl);
        _this.onSwitchView(PaginatedState.MoreInfo);
    };
    

    
    (function _initialize()
    {
        _this.onShowDebugDetails(false);
        _this.tenantBranding = BrandingHelpers.loadTenantBranding(_dynamicTenantBranding);
        _this.unsafe_appName = BrowserHelper.htmlUnescape(_appName);
        _this.unsafe_consentErrorDesc = StringHelpers.format(_strings["CT_STR_Consent_Error_Description"], _this.unsafe_appName);
        _this.customTitle(_strings["CT_STR_Consent_Error_Header"]);

        if (!_isCustomizationFailure && _customStrings)
        {
            require.ensure([],
                function ()
                {
                    var StringCustomizationHelper = require("../Core/StringCustomizationHelper");
                    var errorName = StringCustomizationHelper.resolveErrorPageErrorName(_errorCode.toString());
                    if (errorName)
                    {
                        _this.customTitle(StringCustomizationHelper.parseCustomHeader(_customStrings, errorName) || _this.customTitle());
                        _this.customDescription(StringCustomizationHelper.parseCustomBody(_customStrings, errorName));
                    }
                },
                "StringCustomizationHelper");
        }

        if (_isAppDomainUnverified)
        {
            _this.publisherWebsite = _strings["STR_Consent_PublishedByUnverified"];
        }
        else if (_appWebsite)
        {
            _this.publisherWebsite = _appWebsite;
            _this.domainNameState = "verified";
        }

        if (_appVerifiedPublisherName)
        {
            _this.unsafe_appVerifiedPublisherName = BrowserHelper.htmlUnescape(_appVerifiedPublisherName);
            _this.unsafe_appVerifiedPublisher_AriaLabel = StringHelpers.format(_strings["STR_Consent_PublisherVerified_Label"], _this.unsafe_appVerifiedPublisherName);
        }

        if (_isM365Certified)
        {
            _this.unsafe_isM365CertifiedAriaLabel = StringHelpers.format(_strings["STR_Consent_AppCertified_Description"], _this.unsafe_appName);
        }
    })();
    
}

ko.components.register("consent-error-view",
    {
        viewModel: ConsentErrorViewModel,
        template: require("html/ErrorPage/ConsentErrorViewHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = ConsentErrorViewModel;