var Helpers = require("./Helpers");

var StringHelpers = Helpers.String;


exports.applyExtensions = function (ko)
{
    ko.bindingHandlers.accessibleHtml =
    {
        init: function (element, valueAccessor)
        {
            var agreementText = StringHelpers.trim(ko.unwrap(valueAccessor()));

            var htmlpTagFirst = "<p id=\"agreementTitle\" tabindex=\"-1\">";
            var htmlpTagOpen = "<p tabindex=\"-1\">";
            var htmlpTagClose = "</p>";
            var newLineRegex = /(?:\r\n|\r|\n)/g;
            var emptyParagraphRegex = new RegExp(htmlpTagOpen + htmlpTagClose, "g");

            
            var agreementHtml = htmlpTagFirst + agreementText + htmlpTagClose;

            
            agreementHtml = agreementHtml.replace(newLineRegex, htmlpTagClose + htmlpTagOpen);

            
            agreementHtml = agreementHtml.replace(emptyParagraphRegex, "");

            element.innerHTML = agreementHtml;
        }
    };
};