var ko = require("knockout");

var Browser = require("../Core/BrowserControl");
var ComponentEvent = require("../Core/ComponentEvent");
var BrandingHelpers = require("../Core/BrandingHelpers");

var w = window;
var BrowserHelper = Browser.Helper;

function ConsentRequestSentViewModel(params)
{
    var _this = this;

    
    var _serverData = params.serverData;

    var _cancelPostParams = _serverData.oCancelUriParams;
    var _dynamicTenantBranding = _serverData.dynamicTenantBranding;
    var _cancelUrl = _serverData.urlCancel;
    var _appName = _serverData.strHeaderText;
    var _appLogoUrl = _serverData.urlAppLogo;
    

    
    _this.onRedirect = ComponentEvent.create();
    _this.onShowDebugDetails = ComponentEvent.create();
    

    
    _this.tenantBranding = null;
    _this.unsafe_appName = null;

    _this.appLogoUrl = ko.observable(_appLogoUrl).extend({ loadImageFromUrl: null });
    

    
    _this.saveSharedData = function ()
    {
    };

    _this.getState = function ()
    {
    };

    _this.restoreState = function ()
    {
    };
    

    
    _this.primaryButton_onClick = function ()
    {
        _this.onRedirect(_cancelUrl, _cancelPostParams);
    };
    

    
    (function _initialize()
    {
        _this.onShowDebugDetails(false);
        _this.tenantBranding = BrandingHelpers.loadTenantBranding(_dynamicTenantBranding);
        _this.unsafe_appName = BrowserHelper.htmlUnescape(_appName);
    })();
    
}

ko.components.register("consent-request-sent-view",
    {
        viewModel: ConsentRequestSentViewModel,
        template: require("html/ErrorPage/ConvergedConsentRequestSentViewHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = ConsentRequestSentViewModel;