var ko = require("knockout");

var Browser = require("../../Core/BrowserControl");
var ComponentEvent = require("../../Core/ComponentEvent");
var ConsentConstants = require("../ConsentPage/ConsentConstants");

var w = window;

function ConsentReportSuspiciousAppViewModel(params)
{
    var _this = this;

    
    var _serverData = params.serverData;
    var _activeResourceAppIndex = params.activeResourceAppIndex;
    

    
    var _strings = _serverData.str;
    var _postUrl = _serverData.urlPostReportSuspiciousApp;
    var _appName = _serverData.sAppName || _serverData.strHeaderText;
    var _appWebsite = _serverData.sAppWebsite || _serverData.strAppRootDomain;
    var _displayResourceAppConsentDetails = _serverData.fDisplayResourceAppConsentDetails;
    var _resourceApps = _serverData.arrResourceAppsRequestingPermissions || [];
    

    
    _this.onSwitchView = ComponentEvent.create();
    _this.onSubmitReady = ComponentEvent.create();
    _this.onSetBackButtonState = ComponentEvent.create();
    _this.onUpdatePostUrl = ComponentEvent.create();
    

    
    _this.reportSuspiciousAppOptions = ConsentConstants.ReportSuspiciousAppOptions;

    _this.suspiciousAppOptionSelected = ko.observable("");

    _this.appName = _appName;
    _this.appWebsite = _appWebsite;
    

    
    _this.saveSharedData = function ()
    {
    };

    _this.getState = function ()
    {
    };

    _this.restoreState = function ()
    {
    };

    _this.getRsaOptionText = function (rsaOption)
    {
        switch (rsaOption)
        {
            case "RsaDTA":
                return _strings["STR_Consent_RSAOption_DontTrustTheApp"];
            case "RsaAIAA":
                return _strings["STR_Consent_RSAOptions_AppIsImpersonatingAnotherApp"];
            case "RsaAMUD":
                return _strings["STR_Consent_RSAOption_AppIsMisusingUserData"];
            case "RsaOR":
                return _strings["STR_Consent_RSAOption_Other"];
        }
    };
    

    
    _this.primaryButton_onClick = function ()
    {
        if (_postUrl)
        {
            _this.onUpdatePostUrl(_postUrl);
        }

        _this.onSubmitReady();
    };
    

    (function _initialize()
    {
        _this.onSetBackButtonState(true);

        if (_displayResourceAppConsentDetails && _resourceApps.length > 0 && _activeResourceAppIndex >= 0)
        {
            var _currentResourceApp = _resourceApps[_activeResourceAppIndex];

            if (_currentResourceApp)
            {
                _this.appName = _currentResourceApp.appName;
                _this.appWebsite = _currentResourceApp.appWebsiteUrl;
            }
        }
        else
        {
            _this.appName = _appName;
            _this.appWebsite = _appWebsite;
        }
    })();
}

ko.components.register("consent-report-suspicious-app-view",
    {
        viewModel: ConsentReportSuspiciousAppViewModel,
        template: require("html/ConsentPages/ConsentPage/ConsentReportSuspiciousAppHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = ConsentReportSuspiciousAppViewModel;