var ko = require("knockout");
var Browser = require("./BrowserControl");

var BrowserHelper = Browser.Helper;

var w = window;

function AppOrTenantInfoControl(params)
{
    var _this = this;

    var _name = params.name;
    var _additionalInfo = params.additionalInfo;
    var _tileLogoUrl = params.tileLogoUrl;

    
    _this.tileLogoUrl = ko.observable(_tileLogoUrl).extend({ loadImageFromUrl: null });

    _this.unsafe_name = null;
    _this.unsafe_additionalInfo = null;
    

    

    (function _initialize()
    {
        _this.unsafe_name = BrowserHelper.htmlUnescape(_name);
        _this.unsafe_additionalInfo = BrowserHelper.htmlUnescape(_additionalInfo);
    })();
    
}

ko.components.register("app-or-tenant-info-control",
    {
        viewModel: AppOrTenantInfoControl,
        template: require("html/Shared/Controls/AppOrTenantInfoControlHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = AppOrTenantInfoControl;