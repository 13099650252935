var ko = require("knockout");

var Constants = require("../Core/Constants");
var Browser = require("../Core/BrowserControl");
var ComponentEvent = require("../Core/ComponentEvent");
var BrandingHelpers = require("../Core/BrandingHelpers");

var w = window;
var PaginatedState = Constants.PaginatedState;

function NotAllowedTenantViewModel(params)
{
    var _this = this;

    
    var _serverData = params.serverData;

    var _dynamicTenantBranding = _serverData.dynamicTenantBranding;
    var _hideBrandingOnNotAllowedTenantPage = _serverData.fHideBrandingOnNotAllowedTenantPage;
    

    
    _this.onSwitchView = ComponentEvent.create();
    _this.onShowDebugDetails = ComponentEvent.create();
    

    
    _this.tenantBranding = null;
    

    
    _this.getState = function ()
    {
    };

    _this.restoreState = function ()
    {
    };
    

    
    _this.primaryButton_onClick = function ()
    {
        _this.onSwitchView(PaginatedState.Previous);
    };
    

    (function _initialize()
    {
        _this.tenantBranding = _hideBrandingOnNotAllowedTenantPage ? null : BrandingHelpers.loadTenantBranding(_dynamicTenantBranding);
    })();
}

ko.components.register("not-allowed-tenant-view",
    {
        viewModel: NotAllowedTenantViewModel,
        template: require("html/ErrorPage/NotAllowedTenantViewHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = NotAllowedTenantViewModel;