

var ko = require("knockout");

var Browser = require("../Core/BrowserControl");
var ComponentEvent = require("../Core/ComponentEvent");
var BrandingHelpers = require("../Core/BrandingHelpers");

var w = window;
var BrowserHelper = Browser.Helper;
var Host = null;

if (__IS_CXH_ENABLED__)
{
    Host = require("../LoginPage/Win10LoginHost.js");
}

function ErrorViewModel(params)
{
    var _this = this;

    
    var _serverData = params.serverData;
    var _customStrings = params.customStrings;
    var _isCustomizationFailure = params.isCustomizationFailure;

    var _strings = _serverData.str;
    var _appDisplayName = _serverData.strAppDisplayName;
    var _stackTrace = _serverData.strStackTrace;
    var _dynamicTenantBranding = _serverData.dynamicTenantBranding;
    var _exceptionMessage = _serverData.strServiceExceptionMessage;
    var _errorCode = _serverData.iErrorCode;
    

    
    _this.onSubmitReady = ComponentEvent.create();

    
    _this.unsafe_exceptionMessage = null;
    _this.tenantBranding = null;
    _this.stackTrace = null;
    _this.unsafe_headerText = ko.observable(null);
    _this.customDescription = ko.observable(null);

    _this.isRequestPending = ko.observable(false);

    
    _this.getState = function ()
    {
    };

    _this.restoreState = function ()
    {
    };

    
    _this.primaryButton_onClick = function ()
    {
        if (Host && Host.handleOnSkip)
        {
            Host.handleOnSkip(_serverData);
            _this.isRequestPending(true);
        }
    };

    _this.secondaryButton_onClick = function ()
    {
        if (Host && Host.handleOnFinalBack)
        {
            Host.handleOnFinalBack(_serverData);
            _this.isRequestPending(true);
        }
    };
    

    (function _initialize()
    {
        _this.tenantBranding = BrandingHelpers.loadTenantBranding(_dynamicTenantBranding);
        _this.stackTrace = BrowserHelper.htmlUnescape(_stackTrace);
        _this.unsafe_exceptionMessage = BrowserHelper.htmlUnescape(_exceptionMessage);

        if (_appDisplayName)
        {
            _this.unsafe_headerText(BrowserHelper.htmlUnescape(_appDisplayName));
        }
        else
        {
            _this.unsafe_headerText(_strings["CT_STR_Error_Title"]);
        }

        if (!_isCustomizationFailure && _customStrings)
        {
            require.ensure([],
                function ()
                {
                    var StringCustomizationHelper = require("../Core/StringCustomizationHelper");

                    var errorName = StringCustomizationHelper.resolveErrorPageErrorName(_errorCode);
                    if (errorName)
                    {
                        _this.unsafe_headerText(StringCustomizationHelper.parseCustomHeader(_customStrings, errorName) || _this.unsafe_headerText());
                        _this.customDescription(StringCustomizationHelper.parseCustomBody(_customStrings, errorName));
                    }
                },
                "StringCustomizationHelper");
        }
    })();
}

ko.components.register("error-view",
    {
        viewModel: ErrorViewModel,
        template: require("html/ErrorPage/ErrorViewHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = ErrorViewModel;