var ko = require("knockout");

var Constants = require("../Core/Constants");
var Browser = require("../Core/BrowserControl");
var ComponentEvent = require("../Core/ComponentEvent");

var w = window;
var PaginatedState = Constants.PaginatedState;
var CredentialType = Constants.CredentialType;
var EstsError = Constants.EstsError;

function CertificateAuthErrorViewModel(params)
{
    var _this = this;

    
    var _serverData = params.serverData;
    

    
    var _cancelPostParams = _serverData.oCancelUriParams;
    var _cancelUrl = _serverData.urlCancel;
    var _resumePostParams = _serverData.oResumePostParams;
    var _resumeUrl = _serverData.urlResume;
    var _errorCode = _serverData.iErrorCode;
    var _showIssuerHintErrorStrings = _serverData.fShowIssuerHintErrorStrings;
    

    
    _this.onSwitchView = ComponentEvent.create();
    _this.onRedirect = ComponentEvent.create();
    _this.onShowDebugDetails = ComponentEvent.create();
    

    
    _this.availableCreds = null;
    _this.isIssuerHintError = _showIssuerHintErrorStrings && (_errorCode && _errorCode.toString() === EstsError.IssuerHintsPropagationDelay);
    

    
    _this.getState = function ()
    {
    };

    _this.restoreState = function ()
    {
    };
    

    
    _this.cancel_onClick = function ()
    {
        _this.onRedirect(_cancelUrl, _cancelPostParams);
    };

    _this.switchToEvictedCredPicker_onClick = function ()
    {
        _this.onSwitchView(PaginatedState.CredentialPicker);
    };

    _this.credSwitchLink_onSwitchView = function (viewId)
    {
        if (viewId === PaginatedState.CredentialPicker && _resumeUrl)
        {
            _this.onRedirect(_resumeUrl, _resumePostParams);
        }
    };
    

    
    (function _initialize()
    {
        if (_resumeUrl)
        {
            
            
            _this.availableCreds = [{ credType: CredentialType.Password, shownOnlyOnPicker: true }];
        }
    })();
    
}

ko.components.register("certificate-auth-error-view",
    {
        viewModel: CertificateAuthErrorViewModel,
        template: require("html/ErrorPage/CertificateAuthErrorViewHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = CertificateAuthErrorViewModel;