exports.PaginatedState =
{
    None: 0,
    IsvError: 1,
    AdminConsentError: 2,
    ConsentRequestAccess: 3,
    ConsentRequestSent: 4,
    AdminConsentCustomerSolutionProviderError: 5,
    NotAllowedTenant: 6,
    CertificateAuthError: 7,
    TfaError: 8
};

exports.ESTSErrorCodes =
{
    NotAllowedTenantRestrictedTenant: 500021,
    NotAllowedTenantRestrictionsV2Tenant: 5000211,
    NotAllowedByOutboundPolicyTenant: 500212,
    NotAllowedByInboundPolicyTenant: 500213,
    NotAllowedTenantBlockedTenant: 500022,
    NotAllowedTenantBlockedBlackforestTenant: 5000221,
    XtapAcceptMfaSettingNotConfigured: 90071
};

exports.MSAErrorCodes =
{
    NotAllowedTenantRestrictedTenant: "80045C4D"
};

exports.ErrorTitle =
{
    None: -1,
    SignIn: 0,
    PleaseWait: 1,
    ServerError: 2,
    WeCannotSignYouIn: 3
};

exports.ErrorDesc =
{
    None: -1,
    TroubleSigningIn: 0,
    TroubleSigningOut: 1,
    UpdateExpiredPwd_Err_OnPremSuccessCloudFail: 2,
    UpdateExpiredPwd_Err_ChangePasswordOnPrem: 3,
    UpdateExpiredPwd_Err_ADAdminActionRequired: 4,
    Err_UnableToDownloadResources: 5,
    Err_Corrupted_JSRuntime: 6,
    XtapAcceptMfaSettingNotConfigured: 7
};