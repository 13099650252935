
var ko = require("knockout");

var BrandingHelpers = require("../Core/BrandingHelpers");
var Constants = require("../Core/Constants");
var ErrorConstants = require("../ErrorPage/ErrorPageConstants");
var PaginationControlHelper = require("../Core/PaginationControlHelper");
var StringCustomizationObservables = require("../Core/StringCustomizationObservables");
var ClientTracingHelper = require("../Core/ClientTracingHelper").getInstance(window.ServerData);

var PaginatedState = Constants.PaginatedState;
var StringCustomizationPageId = Constants.StringCustomizationPageId;

module.exports = function (serverData)
{
    
    var _this = this;
    var _serverData = serverData;
    var _customizationLoader = ko.observable(null);
    

    
    var _dynamicTenantBranding = _serverData.dynamicTenantBranding;
    var _staticTenantBranding = _serverData.staticTenantBranding;
    var _appCobranding = _serverData.oAppCobranding;
    var _backgroundImage = _serverData.iBackgroundImage;
    var _timestamp = _serverData.strTimestamp;
    var _initialViewId = _serverData.iViewId;
    var _isDebugTracingEnabled = _serverData.fIsDebugTracingEnabled;
    var _isGlobalTenant = _serverData.isGlobalTenant;
    var _postUrl = _serverData.urlPost;
    var _cancelUrl = _serverData.urlCancel;
    var _hideBrandingOnNotAllowedTenantPage = _serverData.fHideBrandingOnNotAllowedTenantPage;
    var _defaultFaviconUrl = _serverData.urlDefaultFavicon;
    

    
    _this.initialViewId = _initialViewId;
    _this.isDebugTracingEnabled = _isDebugTracingEnabled;

    _this.paginationControlMethods = ko.observable();
    _this.backgroundControlMethods = ko.observable();
    _this.instrumentationMethods = ko.observable();
    _this.footerMethods = ko.observable();
    _this.debugDetailsMethods = ko.observable();
    _this.bannerLogoUrl = ko.observable();
    _this.backgroundLogoUrl = ko.observable();
    _this.useDefaultBackground = ko.observable(false);
    _this.forceSubmit = ko.observable(false);
    _this.fadeInLightBox = ko.observable(false);
    _this.postRedirect = ko.observable();
    _this.isRequestPending = ko.observable(false);
    _this.showDebugDetails = ko.observable(true);
    _this.postUrl = ko.observable(_postUrl);
    _this.loadBannerLogo = ko.observable(false);
    _this.paginationControlHelper = new PaginationControlHelper(_serverData, _this.paginationControlMethods, _this.backgroundLogoUrl);
    _this.stringCustomizationObservables = new StringCustomizationObservables(_customizationLoader);

    _this.debugDetails =
    {
        timestamp: _timestamp
    };
    

    
    _this.paginationControl_onCancel = function ()
    {
        _this.view_onRedirect(_cancelUrl);
    };

    _this.view_onLoadView = function ()
    {
        return null;
    };

    _this.view_onShow = function ()
    {
        var branding;

        if (_hideBrandingOnNotAllowedTenantPage)
        {
            
            branding = BrandingHelpers.getPageBranding(null, null, _backgroundImage);
        }
        else
        {
            var dynamicTenantBranding = BrandingHelpers.loadTenantBranding(_dynamicTenantBranding);
            var staticTenantBranding = BrandingHelpers.loadTenantBranding(_staticTenantBranding);
            var mergedBranding = BrandingHelpers.getMergedBranding(staticTenantBranding, dynamicTenantBranding, _isGlobalTenant);
            branding = BrandingHelpers.getPageBranding(mergedBranding, _appCobranding, _backgroundImage);

            _this.bannerLogoUrl(branding.bannerLogoUrl);
            _this.backgroundLogoUrl(branding.backgroundLogoUrl);
            _this.loadBannerLogo(_this.paginationControlHelper.showLogo());

            BrandingHelpers.updateMergedBrandingObservables(_this, mergedBranding);
            BrandingHelpers.updateFavicon(mergedBranding, _defaultFaviconUrl);
        }

        if (_this.backgroundControlMethods())
        {
            _this.backgroundControlMethods().updateBranding(branding);
        }

        _this.useDefaultBackground(branding.useDefaultBackground);
    };

    _this.view_onUpdatePostUrl = function (url)
    {
        _this.postUrl(url);
    };

    _this.view_onSubmitReady = function ()
    {
        _this.instrumentationMethods().recordSubmit();
        _this.isRequestPending(true);
        _this.forceSubmit(true);
    };

    _this.view_onRedirect = function (redirectData, postParams)
    {
        var parameters = { postParams: postParams };
        var url = ClientTracingHelper.logRedirection(redirectData, parameters);
        _this.isRequestPending(true);

        if (postParams)
        {
            _this.postRedirect({ url: url, postParams: postParams });
        }
        else
        {
            document.location.assign(url);
        }
    };

    _this.view_onSetLightBoxFadeIn = function (fadeIn)
    {
        _this.fadeInLightBox(fadeIn);
    };

    _this.footer_agreementClick = function (agreementType)
    {
        _this.agreementType = agreementType;

        _this.paginationControlMethods().view_onSwitchView(PaginatedState.ViewAgreement);
    };

    _this.closeDebugDetails_onClick = function ()
    {
        _setDebugDetailsState(false);

        if (_this.footerMethods())
        {
            _this.footerMethods().setDebugDetailsState(false);
        }
    };

    _this.toggleDebugDetails_onClick = function ()
    {
        _setDebugDetailsState(!_this.showDebugDetails(), true);
    };

    _this.setDebugTracing_onClick = function ()
    {
        _this.isDebugTracingEnabled = !_this.isDebugTracingEnabled;
    };
    

    
    function _setDebugDetailsState(state, activateDebugDetailsFocus)
    {
        _this.showDebugDetails(state);

        if (state && activateDebugDetailsFocus)
        {
            _activateDebugDetailsFocus();
            return;
        }

        if (!state && _this.footerMethods())
        {
            _this.footerMethods().focusMoreInfoLink();
        }
    }

    function _activateDebugDetailsFocus()
    {
        if (_this.debugDetailsMethods())
        {
            _this.debugDetailsMethods().activateFocus();
        }
    }

    (function _initialize()
    {
        BrandingHelpers.createMergedBrandingObservables(_this);

        if (__CUSTOMIZATION_LOADER_ENABLED__)
        {
            var staticTenantBranding = BrandingHelpers.loadTenantBranding(_staticTenantBranding);
            var dynamicTenantBranding = BrandingHelpers.loadTenantBranding(_dynamicTenantBranding);
            var mergedBranding = BrandingHelpers.getMergedBranding(staticTenantBranding, dynamicTenantBranding, _isGlobalTenant);

            BrandingHelpers.createCustomizationLoader(_serverData, mergedBranding, _customizationLoader, StringCustomizationPageId.ErrorPage);
        }

        if (_this.initialViewId === ErrorConstants.PaginatedState.CertificateAuthError)
        {
            _this.showDebugDetails = ko.observable(false);
        }
    })();
    
};