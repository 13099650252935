var ko = require("knockout");

var Browser = require("../Core/BrowserControl");
var ComponentEvent = require("../Core/ComponentEvent");
var BrandingHelpers = require("../Core/BrandingHelpers");

var w = window;

function TfaErrorViewModel(params)
{
    var _this = this;

    
    var _serverData = params.serverData;
    var _dynamicTenantBranding = _serverData.dynamicTenantBranding;
    

    
    var _strings = _serverData.str;
    var _cancelPostParams = _serverData.oCancelUriParams;
    var _cancelUrl = _serverData.urlCancel;
    var _resumePostParams = _serverData.oResumePostParams;
    var _resumeUrl = _serverData.urlResume;
    

    
    _this.onSwitchView = ComponentEvent.create();
    _this.onRedirect = ComponentEvent.create();
    _this.onShowDebugDetails = ComponentEvent.create();
    

    
    _this.tenantBranding = null;
    _this.showErrorSubheading = ko.observable(false);
    _this.showSwitchToCredPickerLink = ko.observable(false);
    _this.showFooterButtons = ko.observable(!_serverData.fExternalAuthErrorRemoveFooterButtons);
    

    
    _this.getState = function ()
    {
    };

    _this.restoreState = function ()
    {
    };
    

    
    _this.cancel_onClick = function ()
    {
        _this.onRedirect(_cancelUrl, _cancelPostParams);
    };

    _this.switchToCredPicker_onClick = function ()
    {
        _this.onRedirect(_resumeUrl, _resumePostParams);
    };
    

    
    (function _initialize()
    {
        _this.tenantBranding = BrandingHelpers.loadTenantBranding(_dynamicTenantBranding);
        _this.showErrorSubheading(!!_strings["STR_Tfa_Error_Subheading"]);
        _this.showSwitchToCredPickerLink(!!_resumeUrl);
    })();
    
}

ko.components.register("tfa-error-view",
    {
        viewModel: TfaErrorViewModel,
        template: require("html/ErrorPage/TfaErrorViewHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = TfaErrorViewModel;