var ko = require("knockout");
var Browser = require("../../Core/BrowserControl");
var Helpers = require("../../Core/Helpers");

var BrowserHelper = Browser.Helper;
var StringHelpers = Helpers.String;

var w = window;

var requireSprite = require.context("images", false, /^.+?MSFTLI_2wayOauth_sprite.*?\.(png|svg)$/);

function ConsentScopeField(params)
{
    var _this = this;

    
    var _serverData = params.serverData;
    var _label = params.label;
    var _description = params.description;
    var _icon = params.icon;
    var _autoShowTooltip = params.autoShowTooltip;
    var _toolTipLocation = params.toolTipLocation;
    var _toolTipTitle = params.toolTipTitle;
    var _adminLabel = params.adminLabel;
    var _adminDescription = params.adminDescription;
    var _showAdminStrings = params.showAdminStrings;
    

    
    var _strings = _serverData.str;
    var _companyName = _serverData.sCompanyDisplayName;
    var _useMobileImprovements = _serverData.fUseMobileImprovements;
    

    
    var _isSvgImageSupported = false;

    var _isMouseOver = ko.observable(false);
    var _showTooltip = ko.observable(false);
    

    
    _this.unsafe_label = null;
    _this.unsafe_description = null;
    _this.unsafe_orgName = null;
    _this.unsafe_fromTenant = null;
    _this.icon = _icon;
    _this.label = _label;
    _this.description = _description;
    _this.toolTipLocation = _toolTipLocation;
    _this.toolTipTitle = _toolTipTitle;

    _this.spriteImage =
    {
        getLightUrl: function ()
        {
            return requireSprite(_mapServerDataToSpriteImageName(true ));
        },
        getDarkUrl: function ()
        {
            return requireSprite(_mapServerDataToSpriteImageName(false ));
        }
    };

    _this.isFocused = ko.observable(false);
    _this.isPopupDismissLinkFocused = ko.observable(false);

    _this.isOpen = ko.pureComputed(
        function ()
        {
            if (_autoShowTooltip)
            {
                return _this.isFocused() || _isMouseOver();
            }

            return _showTooltip();
        });
    

    
    _this.toggle_onClick = function ()
    {
        var showTooltip = !_showTooltip();
        _showTooltip(showTooltip);

        if (_useMobileImprovements)
        {
            
            
            

            if (showTooltip)
            {
                _this.isPopupDismissLinkFocused(true);
            }
            else
            {
                _this.isFocused(true);
            }
        }
    };

    _this.scope_onMouseEnter = function ()
    {
        _isMouseOver(true);
    };

    _this.scope_onMouseLeave = function ()
    {
        _isMouseOver(false);
    };
    

    
    function _mapServerDataToSpriteImageName(useLight)
    {
        return StringHelpers.format(
            "./MSFTLI_2wayOauth_sprite{0}{1}.{2}",
            _useMobileImprovements ? "_v2" : "",
            useLight ? "_white" : "",
            _isSvgImageSupported ? "svg" : "png");
    }

    (function _initialize()
    {
        _isSvgImageSupported = BrowserHelper.isSvgImgSupported();

        var unsafe_companyName = BrowserHelper.htmlUnescape(_companyName);

        if (_showAdminStrings)
        {
            _this.unsafe_label = BrowserHelper.htmlUnescape(_adminLabel);
            _this.unsafe_description = BrowserHelper.htmlUnescape(_adminDescription);
        }
        else
        {
            _this.unsafe_label = BrowserHelper.htmlUnescape(_label);
            _this.unsafe_description = BrowserHelper.htmlUnescape(_description);
        }

        _this.unsafe_orgName = StringHelpers.format(_strings["STR_Consent_CompanyName"], unsafe_companyName);
        _this.unsafe_fromTenant = StringHelpers.format(_strings["STR_Consent_LinkedIn_Wheel_From_Tenant"], unsafe_companyName);
    })();
    
}

ko.components.register("consent-scope-control",
    {
        viewModel: ConsentScopeField,
        template: require("html/ConsentPages/ConsentPage/ConsentScopeControlHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = ConsentScopeField;