var ko = require("knockout");
var Ajax = require("./AjaxHandlerControl");
var Browser = require("./BrowserControl");
var ComponentEvent = require("./ComponentEvent");
var Constants = require("./Constants");
var Helpers = require("./Helpers");
var ViewAgreementKnockoutExtensions = require("./ViewAgreementKnockoutExtensions");

var w = window;
var AgreementType = Constants.AgreementType;
var StringHelpers = Helpers.String;
var BrowserHelper = Browser.Helper;

ViewAgreementKnockoutExtensions.applyExtensions(ko);

function ViewAgreementControl(params)
{
    var _this = this;
    var _requestUrl = null;

    
    var c_agreementDiv = "idDiv_iAgreement";
    

    
    var _serverData = params.serverData;
    var _agreementType = params.agreementType;
    

    
    var _strings = _serverData.str;
    var _urlPrivacyStatement = _serverData.urlHostedPrivacyLink;
    var _urlServicesAgreement = _serverData.urlHostedTOULink;
    var _urlImpressumAgreement = _serverData.urlImpressum;
    var _urlPrivacyStatementExternal = _serverData.urlFooterPrivacy;
    var _urlServicesAgreementExternal = _serverData.urlFooterTOU;
    var _urlA11yConformeAgreement = _serverData.a11yConformeLink;
    var _hideTopViewAgreementBackButton = _serverData.fHideTopViewAgreementBackButton;
    var _isFluent = _serverData.fShowFluent;
    var _showButtons = _serverData.fShowButtons;
    

    
    _this.onButtonClick = ComponentEvent.create();
    _this.onSetBackButtonState = ComponentEvent.create();
    

    
    _this.hideTopButton = _hideTopViewAgreementBackButton;

    _this.agreementHtml = ko.observable();
    _this.downloadError = ko.observable(false);
    _this.secondaryButtonDescribedBy = ko.observable();
    

    
    _this.secondaryButton_onClick = function ()
    {
        _this.onButtonClick();
    };
    

    
    function _successCallback(event, agreementText)
    {
        _this.secondaryButtonDescribedBy(c_agreementDiv);
        _this.agreementHtml(agreementText);
    }

    function _failureCallback()
    {
        _this.secondaryButtonDescribedBy(c_agreementDiv);
        _this.downloadError(true);
    }

    function _getAgreement()
    {
        var requestParams =
            {
                targetUrl: _requestUrl,
                requestType: Ajax.RequestType.Get,
                isAsync: true,
                timeout: 10000,
                responseType: "text",
                successCallback: _successCallback,
                failureCallback: _failureCallback,
                timeoutCallback: _failureCallback
            };

        var request = new Ajax.Handler(requestParams);
        request.sendRequest();
    }

    (function _initialize()
    {
        _this.onSetBackButtonState(
            _isFluent && _showButtons, 
            true, 
            c_agreementDiv );

        if ((_agreementType === AgreementType.Impressum
                && _urlImpressumAgreement)
            || (_agreementType === AgreementType.A11yConforme
                && _urlA11yConformeAgreement)
            || (_agreementType === AgreementType.Privacy
                && _urlPrivacyStatementExternal)
            || (_agreementType === AgreementType.Tou
                && _urlServicesAgreementExternal))
        {
            var externalLink = null;

            switch (_agreementType)
            {
                case AgreementType.Impressum:
                    externalLink = _urlImpressumAgreement;
                    break;
                case AgreementType.A11yConforme:
                    externalLink = _urlA11yConformeAgreement;
                    break;
                case AgreementType.Privacy:
                    externalLink = _urlPrivacyStatementExternal;
                    break;
                case AgreementType.Tou:
                    externalLink = _urlServicesAgreementExternal;
                    break;
            }

            _this.hideTopButton = true;
            _this.secondaryButtonDescribedBy(c_agreementDiv);
            _this.agreementHtml(StringHelpers.format(_strings["CT_STR_ViewAgreement_ExternalLink"], BrowserHelper.htmlEscape(externalLink)));
        }
        else
        {
            _requestUrl = _agreementType === AgreementType.Privacy ? _urlPrivacyStatement : _urlServicesAgreement;
            _getAgreement();
        }
    })();
    
}

ko.components.register("view-agreement-control",
    {
        viewModel: ViewAgreementControl,
        template: require("html/Shared/Controls/ViewAgreementControlHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = ViewAgreementControl;