

var ko = require("knockout");
var Browser = require("../Core/BrowserControl");
var ComponentEvent = require("../Core/ComponentEvent");
var Constants = require("../Core/Constants");
var Host = null;

if (__IS_INCLUSIVE_OOBE__)
{
    Host = require("./InclusiveWin10LoginHost.js");
}

var w = window;
var PaginatedState = Constants.PaginatedState;

function LoginViewAgreementViewModel(params)
{
    var _this = this;

    
    var _agreementType = params.agreementType;

    
    _this.onSwitchView = ComponentEvent.create();

    
    _this.agreementType = _agreementType;

    
    _this.saveSharedData = function ()
    {
    };

    _this.getState = function ()
    {
        return null;
    };

    _this.restoreState = function ()
    {
    };

    
    _this.agreementControl_onBack = function ()
    {
        _this.onSwitchView(PaginatedState.Previous);
    };

    (function _initialize()
    {
        if (Host && Host.handleBackButton)
        {
            Host.handleBackButton(_this.agreementControl_onBack.bind(_this));
        }
    })();
}

ko.components.register("login-view-agreement-view",
    {
        viewModel: LoginViewAgreementViewModel,
        template: require("html/LoginPage/ViewAgreementViewHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = LoginViewAgreementViewModel;